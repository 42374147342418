<template>
  <div>
    <nav class="navbar is-fixed-top is-flex is-flex-direction-column has-transparent-background navbar-mobile"
         :class="navbarClasses">
      <div class="pism-banner" v-if="isPismEvent" v-prevent-parent-scroll>
        <div class="pism-prezydent-text-container">
          <span class="is-semibold">{{ $t("custom.pism_banner_part_normal") }}</span>
        </div>
        <a href="https://prezydent.pl" target="_blank" class="pism-prezydent">
          <pism-prezydent></pism-prezydent>
        </a>
      </div>
      <magic-link-banner></magic-link-banner>

      <div class="navbar is-flex is-flex-direction-row" :style="navbarStyle">
        <div class="navbar-container has-navbar">
          <!-- Brand -->
          <div class="navbar-brand" v-prevent-parent-scroll>
            <router-link
              class="is-flex is-align-items-center is-justify-content-flex-start pl-4 is-flex-shrink-1 is-flex-grow-1"
              :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: '#home' }"
              @click.native="scrollToModule('#home')"
            >
              <div class="is-inline-flex has-logo is-clipped" :class="{ box: eventLogoWithOutline }">
                <figure v-if="logoUrl" class="image is-centered" :style="logoStyles">
                  <img class="logo-image" :src="logoUrl" alt="Logo Image"/>
                </figure>
              </div>
            </router-link>

            <div class="navbar-burger if-flex-grow-0 is-flex-shrink-0 unselectable" :class="{ 'is-active': isOpen }" @click="toggle">
              <template v-if="isOpen">
                <span aria-hidden="true" :style="burgerSpanStyle"></span>
                <span aria-hidden="true" :style="burgerSpanStyle"></span>
                <span aria-hidden="true" :style="burgerSpanStyle"></span>
              </template>
              <template v-else>
                <arrow-down class="menu-icon svg-icon" :style="menuIconStyle"></arrow-down>
              </template>
            </div>

            <div
              class="navbar-burger unselectable is-flex-shrink-0 if-flex-grow-0"
              v-if="isAuthorized && !isSideMenuBubbles"
              :class="{ 'is-active': isSideMenuPanelSneakPeakMode }"
              @click="toggleSideMenuPanelSneakPeakMode"
            >
              <span aria-hidden="true" :style="burgerSpanStyle"></span>
              <span aria-hidden="true" :style="burgerSpanStyle"></span>
              <span aria-hidden="true" :style="burgerSpanStyle"></span>
            </div>
          </div>

          <!-- Navigation links -->
          <div class="navbar-menu" :class="{ 'is-active': isOpen }">
            <!-- Anchor links -->

            <!-- Profile -->
            <div class="navbar-start">
              <div class="navbar-item is-flex is-flex-direction-row">
                <nav-profile v-if="hasProfileNav"></nav-profile>
                <nav-locales v-if="localeSelectionEnabled"></nav-locales>
                <nav-help v-if="helpEnabled"></nav-help>
              </div>
            </div>

            <div class="navbar-end has-text-weight-bold" v-prevent-parent-scroll>
              <router-link
                v-for="navModule in allNavModules"
                class="navbar-item"
                :key="navModule.hash"
                :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: navModule.hash }"
                @click.native="navigateToModule(navModule)"
              >
                {{ getCustomTranslation(navModule, LpConfigConstants.COMMON_MODULE_FIELDS.NAVBAR_TITLE) }}
              </router-link>
            </div>


            <!-- Language selection -->
            <!--            <div class="navbar-end" v-prevent-parent-scroll>-->
            <!--              <div class="navbar-item">-->
            <!--        -->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </nav>
    <div class="is-flex is-flex-direction-row">
      <nav class="navbar is-fixed-top navbar-desktop is-flex is-flex-direction-column" :style="navbarStyle"
           :class="navbarClasses">
        <div class="pism-banner" v-if="isPismEvent">
          <div class="pism-prezydent-text-container">
            <span class="is-semibold">{{ $t("custom.pism_banner_part_normal") }}</span>
          </div>
          <a href="https://prezydent.pl" target="_blank" class="pism-prezydent">
            <pism-prezydent></pism-prezydent>
          </a>
        </div>

        <magic-link-banner></magic-link-banner>
        <div class="ma-navbar">
          <!-- Brand -->
          <div class="navbar-brand mr-1">
            <router-link
              class="is-flex is-align-items-center is-justify-content-center"
              :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: '#home' }"
              @click.native="scrollToModule('#home')"
            >
              <div class="is-inline-flex has-logo is-clipped" :class="{ box: eventLogoWithOutline }">
                <figure v-if="logoUrl" class="image is-centered" :style="logoStyles">
                  <img class="logo-image" :src="logoUrl" alt="Logo Image"/>
                </figure>
              </div>
            </router-link>
          </div>

          <!-- Anchor links -->
          <div class="navbar-start has-text-weight-bold">
            <router-link
              v-for="navModule in visibleNavModules"
              class="navbar-item is-button"
              :style="navbarItemStyle"
              :key="navModule.hash"
              :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: navModule.hash }"
              @click.native="navigateToModule(navModule)"
            >
              <div class="button-text">
                {{ getCustomTranslation(navModule, LpConfigConstants.COMMON_MODULE_FIELDS.NAVBAR_TITLE) }}
              </div>
            </router-link>
            <b-dropdown
              v-if="dropdownNavModules.length"
              position="is-bottom-left"
              ref="menuDropdown"
              aria-role="menu"
              trap-focus
              can-close
              close-on-click
            >
              <template slot="trigger">
                <a class="navbar-item" :style="navbarItemStyle"> {{ `${$t("common.more").toUpperCase()}...` }} </a>
              </template>

              <b-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless>
                <div class="has-text-weight-bold">
                  <router-link
                    v-for="navModule in dropdownNavModules"
                    class="navbar-item"
                    :key="navModule.hash"
                    :style="navbarItemStyle"
                    :to="{ name: Constants.ROUTE_MAIN_INTRODUCTION, hash: navModule.hash }"
                    @click.native="navigateToModule(navModule, true)"
                  >
                    {{ getCustomTranslation(navModule, LpConfigConstants.COMMON_MODULE_FIELDS.NAVBAR_TITLE) }}
                  </router-link>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- Language selection -->
          <div class="navbar-end">
            <!-- Profile -->
            <template v-if="hasProfileNav">
              <div class="navbar-item px-0 mr-2" ref="navProfileDesktop">
                <nav-profile :fill="navbarIconStyle"></nav-profile>
              </div>
            </template>

            <div class="navbar-item px-0" :style="navbarItemStyle">
              <nav-help v-if="helpEnabled" :fill="navbarIconStyle"></nav-help>
              <nav-locales v-if="localeSelectionEnabled" :fill="navbarIconStyle"></nav-locales>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {navScrollTo, throttle} from "@/shared/utils";
import {Constants} from "@/web/constants";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import NavProfile from "@/web/components/navbar/NavProfile";
import NavHelp from "@/web/components/navbar/NavHelp";
import NavLocales from "@/web/components/locale/NavLocales";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import PismPrezydent from "@/assets/custom/pism_prezydent.svg";
import ArrowDown from "@/assets/arrow-down.svg";
import MagicLinkBanner from "@/web/components/magiclink/MagicLinkBanner";

const NAVBAR_RESERVED_WIDTH = 300;
const NAVBAR_ITEM_MAX_WIDTH = 150;

export default {
  name: "NavBar",
  mixins: [LpConfigMixin],
  components: {
    MagicLinkBanner,
    NavProfile,
    NavLocales,
    NavHelp,
    ArrowDown,
    PismPrezydent,
  },

  data() {
    return {
      isOpen: false,
      isMobile: false,
      navProfileWidth: 200,
      maxItems: 0,
      scrollListener: null,
    };
  },

  mounted() {
    const body = document.body;
    body.classList.add("has-navbar-fixed-top");
    window.addEventListener("resize", this.onResize, {passive: true});
    this.refreshLayout();
  },

  beforeDestroy() {
    const body = document.body;
    body.classList.remove("has-navbar-fixed-top");
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, {passive: true});
    }
    this.stopOnScrollListener();
  },

  watch: {
    immediate: false,
    isSideMenuPanelOpen: {
      handler: function (newValue) {
        this.refreshLayout();
      },
    },

    currentLocale: {
      immediate: false,
      handler: function (newValue) {
        this.refreshLayout();
      },
    },

    currentUser: {
      immediate: false,
      handler: function (newValue) {
        this.refreshLayout();
      },
    },

    isOpen: {
      immediate: false,
      handler: function (newValue) {
        if (newValue) {
          this.startOnScrollListener();
        }
      },
    },
  },

  methods: {
    ...mapMutations("sideMenu", ["toggleSideMenuPanelSneakPeakMode"]),

    toggle() {
      this.isOpen = !this.isOpen;
    },

    refreshLayout() {
      this.$nextTick(() => {
        this.onResize();
      });
    },

    onResize: throttle(function (newVal) {
      const navProfileWidth = (this.$refs.navProfileDesktop && this.$refs.navProfileDesktop.clientWidth) || 200;
      const newMaxItems = Math.max(
        0,
        Math.floor(
          (window.innerWidth - NAVBAR_RESERVED_WIDTH - navProfileWidth - (this.isSideMenuPanelOpen ? 314 : 90)) / NAVBAR_ITEM_MAX_WIDTH
        ) - 0.3
      );
      if (this.maxItems !== newMaxItems) {
        this.maxItems = newMaxItems;
      }
      const newIsMobile = window.innerWidth < 968;
      if (this.isMobile !== newIsMobile) {
        this.isMobile = newIsMobile;
      }
    }, 80),

    startOnScrollListener() {
      if (!this.scrollListener) {
        this.scrollListener = () => {
          this.isOpen = false;
          this.stopOnScrollListener();
        };
      }
      document.addEventListener("scroll", this.scrollListener);
    },

    stopOnScrollListener() {
      if (this.scrollListener) {
        document.removeEventListener("scroll", this.scrollListener);
      }
    },

    navigateToModule(navModule, closeMenuDropdown) {
      if (navModule.type === Constants.MODULE_COMPONENT_ATTENDEES) {
        if (navModule.visible && navModule.navbar_action === Constants.MODULE_NAVBAR_BEHAVIOUR_SCROLL) {
          this.scrollToModule(navModule.hash);
        } else {
          let routeData = this.$router.resolve({
            name: Constants.ROUTE_ATTENDEES,
            params: {componentId: navModule.component_id},
          });
          window.open(routeData.href, "_self");
        }
      } else if (navModule.type === Constants.MODULE_BUSINESS_MATCHING) {
        if (navModule.visible && navModule.navbar_action === Constants.MODULE_NAVBAR_BEHAVIOUR_SCROLL) {
          this.scrollToModule(navModule.hash);
        } else {
          let routeData = this.$router.resolve({
            name: Constants.ROUTE_BUSINESS_MATCHING,
          });
          window.open(routeData.href, "_self");
        }
      } else if (navModule.type === Constants.MODULE_QUIZZES) {
        if (navModule.visible && navModule.navbar_action === Constants.MODULE_NAVBAR_BEHAVIOUR_SCROLL) {
          this.scrollToModule(navModule.hash);
        } else {
          let routeData = this.$router.resolve({
            name: Constants.ROUTE_QUIZZES,
            params: {componentId: navModule.component_id},
          });
          window.open(routeData.href, "_self");
        }
      } else if (navModule.type === Constants.MODULE_NAVBAR_URL_REDIRECT) {
        const modalFields = LpConfigConstants.NAVBAR_FIELDS.CUSTOM_BUTTONS_FIELDS;
        if (navModule[modalFields.URL]) {
          window.open(this.getCustomTranslation(navModule, modalFields.URL), navModule[modalFields.TARGET]);
        }
      } else if (navModule.type === Constants.MODULE_NAVBAR_MODAL) {
        const modalFields = LpConfigConstants.NAVBAR_FIELDS.CUSTOM_BUTTONS_FIELDS;
        this.$root.openInfoModal({
          title: this.getCustomTranslation(navModule, modalFields.MODAL_TITLE, null),
          message: this.getCustomTranslation(navModule, modalFields.MODAL_DESCRIPTION, null),
          imageUrl: this.getCustomTranslation(navModule, modalFields.MODAL_IMAGE_URL, null),
        });
      } else {
        this.scrollToModule(navModule.hash);
      }
      if (this.isMobile) {
        this.toggle();
      }
      if (closeMenuDropdown) {
        this.$refs.menuDropdown.toggle();
      }
    },

    scrollToModule(hash) {
      navScrollTo(hash, "smooth");
    },
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters([
      "lpSettings",
      "navbarModules",
      "hasDisabledAuth",
      "moduleByComponentId",
      "helpEnabled",
      "eventLogoWithOutline",
      "sideMenuType",
    ]),
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("locales", ["localeSelectionEnabled", "currentLocale"]),
    ...mapGetters("currentUser", ["currentUser"]),
    ...mapGetters("sideMenu", ["isSideMenuPanelOpen", "isSideMenuPanelSneakPeakMode"]),

    Constants: () => Constants,
    LpConfigConstants: () => LpConfigConstants,

    isPismEvent() {
      return this.event.id === 1312;
    },

    navbarConfig() {
      return this.lpSettings[LpConfigConstants.ROOT_FIELDS.NAVBAR];
    },

    configNavButtons() {
      return this.navbarConfig && this.navbarConfig[LpConfigConstants.NAVBAR_FIELDS.CUSTOM_BUTTONS];
    },

    allNavModules() {
      let navModules = [...this.navbarModules];
      if (this.configNavButtons && this.configNavButtons.length) {
        this.configNavButtons.forEach(navButton => {
          navModules.splice(navButton.order, 0, navButton);
        });
      }
      return mapNavModules(navModules);
    },

    visibleNavModules() {
      if (this.allNavModules.length > this.maxItems + 1 + this.isMrhEvent + this.isMrhEvent) {
        return this.allNavModules.slice(0, this.maxItems + this.isMrhEvent + this.isMrhEvent);
      } else {
        return this.allNavModules;
      }
    },

    dropdownNavModules() {
      if (this.allNavModules.length > this.maxItems + 1 + this.isMrhEvent + this.isMrhEvent) {
        return this.allNavModules.slice(this.maxItems + this.isMrhEvent + this.isMrhEvent, this.allNavModules.length);
      } else {
        return [];
      }
    },

    isMrhEvent() {
      return this.event.id === 1226;
    },

    logoUrl() {
      if (this.isPismEvent) {
        return require("@/assets/custom/pism/pism_logo_white.png");
      } else {
        const logo = this.event.logo;
        return logo && logo.file_thumbnail_200_url;
      }
    },

    logoStyles() {
      return {
        height: "80px",
        maxWidth: this.getFieldValueOrDefault(this.navbarConfig, LpConfigConstants.NAVBAR_FIELDS.LOGO_WIDTH, "80px"),
        backgroundColor: this.getFieldValueOrDefault(this.navbarConfig, LpConfigConstants.NAVBAR_FIELDS.LOGO_BACKGROUND_COLOR, "transparent"),
      };
    },

    hasProfileNav() {
      return !this.hasDisabledAuth || this.event.id === 1226;
    },

    navbarBackgroundImage() {
      const backgroundImage = this.getCustomTranslation(this.navbarConfig, LpConfigConstants.NAVBAR_FIELDS.BACKGROUND_IMAGE_URL, null);
      if (backgroundImage && backgroundImage.toLowerCase().includes("http")) {
        return `url(${backgroundImage}) !important`;
      } else {
        return backgroundImage;
      }
    },

    navbarStyle() {
      if (this.navbarConfig) {
        return {
          backgroundImage: this.navbarBackgroundImage,
          backgroundColor: this.navbarConfig[LpConfigConstants.NAVBAR_FIELDS.BACKGROUND_COLOR],
          backgroundSize: this.navbarConfig[LpConfigConstants.NAVBAR_FIELDS.BACKGROUND_IMAGE_SIZE],
          backgroundRepeat: this.navbarConfig[LpConfigConstants.NAVBAR_FIELDS.BACKGROUND_IMAGE_REPEAT],
        };
      } else {
        return {};
      }
    },

    navbarClasses() {
      return {
        "has-side-menu-panel": !this.isSideMenuBubbles && this.isAuthorized,
        "side-menu-panel-opened": this.isSideMenuPanelOpen,
        "is-sneak-peak": this.isSideMenuPanelSneakPeakMode,
        "display-none": this.displayNone,
      };
    },

    displayNone() {
      return this.event.id === 299 || this.event.id === 298 || this.event.id === 1399;
      // return false;
    },

    isSideMenuBubbles() {
      return this.sideMenuType === LpConfigConstants.SIDE_MENU_TYPES.BUBBLES;
    },

    navbarItemStyle() {
      if (this.navbarConfig) {
        return {
          color: this.navbarConfig[LpConfigConstants.NAVBAR_FIELDS.BUTTON_TEXT_COLOR],
        };
      } else {
        return {};
      }
    },

    navbarIconStyle() {
      if (this.navbarConfig) {
        return {
          fill: this.navbarConfig[LpConfigConstants.NAVBAR_FIELDS.ICON_COLOR],
        };
      } else {
        return {};
      }
    },

    menuIconStyle() {
      if (this.navbarConfig) {
        return {
          fill: this.navbarConfig[LpConfigConstants.NAVBAR_FIELDS.ICON_COLOR],
          stroke: this.navbarConfig[LpConfigConstants.NAVBAR_FIELDS.ICON_COLOR],
        };
      } else {
        return {};
      }
    },

    burgerSpanStyle() {
      if (this.navbarConfig) {
        return {
          backgroundColor: this.navbarConfig[LpConfigConstants.NAVBAR_FIELDS.ICON_COLOR],
        };
      } else {
        return {};
      }
    },
  },
};

function mapNavModules(modules) {
  // eslint-disable-next-line prettier/prettier
  return modules
    .map(module => {
      if (module.type === Constants.MODULE_NAVBAR_MODAL || module.type === Constants.MODULE_NAVBAR_URL_REDIRECT) {
        return {...module, hash: `#module-navbar-${module.order}`};
      } else {
        return {...module, hash: `#module-${module.order}`};
      }
    })
    .filter(module => !!module.navbar_title);
}
</script>

<style lang="scss" scoped>
.box {
  background: transparent;
}

.pism-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #202131;
  font-size: 10px;
  line-height: 109%;
  color: white;
  white-space: pre-wrap;
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
  text-align: center;

  .pism-prezydent-text-container {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .is-bold {
    font-weight: 700;
  }

  .is-semibold {
    font-weight: 500;
  }

  .pism-prezydent {
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.display-none {
  display: none !important;
}
</style>
