import { render, staticRenderFns } from "./NavOrganizerEmailModal.vue?vue&type=template&id=6ca95bfa&scoped=true&"
import script from "./NavOrganizerEmailModal.vue?vue&type=script&lang=js&"
export * from "./NavOrganizerEmailModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca95bfa",
  null
  
)

export default component.exports